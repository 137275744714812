@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

body {
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 0;
  margin: 0;
}

body {
  color: white;
  background-color: #282A42 !important;
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 24px;
  padding: 5px;
  color: white;
}

/* a { cursor: pointer; color:white }
a.link {
  color: #666CFF
}

button:focus {outline:0;}
.table {
  color: white;
}
.card, .list-group-item {
  background: #30334E;
}
.btn-primary {
  background-color: #666CFF;
  border-color: #666CFF;
}
.btn-outline {
  color: #666CFF;
  background-color: transparent;
  border-color: #666CFF;
  cursor: pointer;
}
.btn-outline:hover {
  color: #666CFF;
}
input.MuiInputBase-input.MuiOutlinedInput-input[type=date][value=""] {
  color: transparent
}
div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused input {
  color: inherit !important
}
.login-panel {
  width: 480px;
  max-width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}
.login-back {
  width: 100%;
  height: 100vh;
  object-fit: cover;
} */